import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';

export default {
  name: 'TemplateLesiurTabs',
  mixins: [gMixin],
  props: {
    hasDealType: {
      type: Array,
      default: () => [],
    },
    banner: {
      type: Array,
      default: () => [],
    },
    bannerHref: {
      type: String,
      default: '',
    },
    showBanner: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      dealData: 'GET_DEAL_DATA',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      isMoreDealData: 'GET_IS_MORE_DEAL_DATA',
      device: 'GET_DEVICE',
      dmscDealData: 'GET_DOMESTIC_DEAL_DATA',
      marketerId: 'GET_MARKETER_ID',
      marketerAgency: 'GET_MARKETER_AGENCY',
      isAgencyLogin: 'IS_AGENCY_AUTHORIZED',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
    }),
    activeIndex() {
      return (this.tabIndex === -1) ? 0 : this.tabIndex;
    },
    dealTypeList() {
      let showDealTypeList = this.hasDealType.map((item) => ({ name: item.name[this.lang], code: item.code, list: [] }));
      if (this.marketerAgency !== '' || this.marketerId !== '') {
        const showMainDeals = this.marketerAgencyContent?.marketerSpecificContents?.showMainDeals ?? true;
        if (!showMainDeals) {
          const tmpShowDealTypeList = [];
          const listDealCategory = this.marketerAgencyContent?.marketerSpecificContents?.dealCategoriesToShow;
          if (listDealCategory && Array.isArray(listDealCategory)) {
            this.hasDealType.forEach((item) => {
              if (listDealCategory.includes(item.code)) {
                tmpShowDealTypeList.push({ name: item.name[this.lang], code: item.code, list: [] });
              }
            });
          }
          showDealTypeList = tmpShowDealTypeList;
        }
        if (navigator.userAgent.indexOf('Firefox') !== -1) { // Mozilla firefox browser.
          showDealTypeList.sort((a, b) => {
            if (a.code.toUpperCase() === this.marketerAgency.toUpperCase()) {
              return -1;
            } else {
              return (b.code.toUpperCase() !== this.marketerAgency.toUpperCase()) ? -1 : 1;
            }
          });
        } else { // The rest browsers.
          showDealTypeList.sort((a) => (a.code.toUpperCase() === this.marketerAgency.toUpperCase() ? -1 : 1));
        }
        if (showDealTypeList.length > 0 && showDealTypeList[0].code.toUpperCase() === this.marketerAgency.toUpperCase()) showDealTypeList[0].name = 'המיוחדים שלנו';
      }
      if (this.marketerId === '' && this.device === 'desktop') showDealTypeList.push({ name: this.$t('home.domestic-tourism'), code: 'domestic', list: [] });
      return showDealTypeList;
    },
    domesticTypeTab() {
      const one = { name: this.$t('home.domestic-tourism'), code: 'domestic', list: [] };

      if (this.marketerId === '' && this.device === 'mobile') {
        if (!this.domesticList) return null;
        one.list = this.domesticList;
        return one;
      }
      return null;
    },
    dealsList() {
      const { dealData, dealTypeList, tabIndex } = this;
      const typeList = [...dealTypeList];
      if (this.device === 'desktop' && this.marketerId === '' && tabIndex === dealTypeList.length - 1) {
        if (!this.domesticList) return null;
        typeList[tabIndex].list = this.domesticList;
        return typeList;
      }
      if (!dealData || (dealData && dealData.length === 0)) return null;

      // Filter the deals matched category and deal that isSalesDeal is true
      const salesDeals = dealData.filter((item) => (item.dealTypeCode === this.curDTCode && item.isSalesDeal));

      // Filter the deals matched category and isSalesDeal is a false
      // const lstFltByDType = dealData.filter((item) => (item.dealTypeCode === this.curDTCode && !item.isSalesDeal && ((this.marketerId && !item.dealGenerationSessionGuid) || !this.marketerId)));

      const listHotelIDSalesDeal = salesDeals.map((sDeal) => sDeal.hotelId);
      const lstFltByDType = [];
      dealData.forEach((item) => {
        if (item.dealTypeCode === this.curDTCode && !item.isSalesDeal) {
          const idx = listHotelIDSalesDeal.indexOf(item.hotelId);
          if (idx > -1) {
            salesDeals.push(item);
          } else {
            lstFltByDType.push(item);
          }
        }
      });

      if (this.curDTCode === 'Flight_Only') {
        typeList[tabIndex].list = Object.entries(this.groupByKey(lstFltByDType, 'destinationCode'));
        // if (salesDeals.length > 0) typeList[tabIndex].list.push(...salesDeals.map((item) => ([String(item.destinationCode), [item]])));
        if (salesDeals.length > 0) typeList[tabIndex].list.push(...Object.entries(this.groupByKey(salesDeals, 'destinationCode')));
      } else if (this.curDTCode === 'Organize_tour_packages') {
        typeList[tabIndex].list = Object.entries(this.groupByKey(lstFltByDType, 'destinationCode', 'nights'));
        // if (salesDeals.length > 0) typeList[tabIndex].list.push(...salesDeals.map((item) => ([String(item.destinationCode), [item]])));
        if (salesDeals.length > 0) typeList[tabIndex].list.push(...Object.entries(this.groupByKey(salesDeals, 'hotelId')));
      } else {
        typeList[tabIndex].list = Object.entries(this.groupByKey(lstFltByDType, 'hotelId'));
        // if (salesDeals.length > 0) typeList[tabIndex].list.push(...salesDeals.map((item) => ([String(item.hotelId), [item]])));
        if (salesDeals.length > 0) typeList[tabIndex].list.push(...Object.entries(this.groupByKey(salesDeals, 'hotelId')));
      }

      typeList[tabIndex].list.sort((a, b) => {
        const priceA = a[1][0].packageType === 'F' ? a[1][0].discountedPriceFO.priceByAge[0].price : a[1][0].discountedPrice.price_average,
          priceB = b[1][0].packageType === 'F' ? b[1][0].discountedPriceFO.priceByAge[0].price : b[1][0].discountedPrice.price_average;
        let result = 0;
        result = priceA < priceB ? -1 : 1;
        return result;
      });
      // const availList = typeList.filter((el) => el.list.length > 0);
      // return typeList.filter((el) => el.list.length > 0);
      // console.log(availList, typeList);

      return typeList;
    },
    domesticList() {
      const tempArray = [];
      const { dmscDealData } = this;

      if (!dmscDealData) return null;

      // Filter the deals matched category and isSalesDeal is a false
      const lstFltByDType = dmscDealData.filter((item) => (!this.isSalesDeal(item)));
      // Filter the deals matched category and deal that isSalesDeal is true
      const salesDeals = dmscDealData.filter((item) => (this.isSalesDeal(item)));

      tempArray.push(...Object.entries(this.groupByKey(lstFltByDType, 'hotelId')));
      if (salesDeals.length > 0) tempArray.push(...salesDeals.map((item) => ([String(item.hotelId), [item]])));

      // sort part
      tempArray.forEach((d) => {
        d[1].sort((a, b) => {
          const priceA = a.totalAfterDiscount, priceB = b.totalAfterDiscount;
          return priceA < priceB ? -1 : 1;
        });
      });
      tempArray.sort((a, b) => {
        if (this.isSalesDeal(a[1][0]) && !this.isSalesDeal(b[1][0])) {
          return -1;
        } else if (!this.isSalesDeal(a[1][0]) && this.isSalesDeal(b[1][0])) {
          return 1;
        }
        return 0;
      });
      tempArray.sort((a, b) => {
        if (a[1][0].priority > b[1][0].priority) {
          return -1;
        } else if (a[1][0].priority < b[1][0].priority) {
          return 1;
        }
        return 0;
      });

      return tempArray;
      // return { name: 'Domestic tourism', code: 'domestic', list: [...tempArray] };
    },
    link() {
      return this.banner[0]?.href || '';
    },
    bannerImage() {
      return encodeURI(this.banner[0]?.image.replaceAll('%20', ' ') || '');
    },
    bannerHeight() {
      const { width } = this;
      return width / (1920 / 500);
    },
    imageList() {
      return this.banner.map((bannerItem) => bannerItem.image.replaceAll('%20', ' ') || '');
    },
    imageLink() {
      return this.banner.map((bannerItem) => bannerItem.href || '#');
    },
    // hideCondition() {
    //   const { isAgencyLogin, isOdysseySite, bypassPaymentState } = this;
    //   const { query } = this.$route;
    //   return (isOdysseySite || (!isAgencyLogin && bypassPaymentState)) && (query.secret !== null && query.secret !== process.env.VUE_APP_TEMPORARY_SECRET_PARM);
    // },
    getFilterConditions() {
      const { filterConditions } = this;
      return JSON.stringify(filterConditions);
    },
  },
  watch: {
    tabIndex() {
      this.curDTCode = this.dealTypeList?.[this.tabIndex]?.code ?? '';
      if (this.marketerId === '') {
        if (this.dealTypeList?.[this.tabIndex]?.code !== 'domestic' && (!this.dealData || this.dealData.findIndex((deal) => deal.dealTypeCode === this.curDTCode) === -1)) {
          this.$store.dispatch('FETCH_DEAL_DATA', { dealType: this.curDTCode, moreDeal: false });
        } else if (!this.dmscDealData || this.dmscDealData.length === 0) {
          this.$store.dispatch('FETCH_DOMESTIC_DEAL');
        }
      } else if ((!this.dealData || this.dealData.findIndex((deal) => deal.dealTypeCode === this.curDTCode) === -1)) {
        this.$store.dispatch('FETCH_DEAL_DATA', { dealType: this.curDTCode, moreDeal: false });
      }
    },
    showDomesticForMobile() {
      if (!this.dmscDealData || this.dmscDealData.length === 0) {
        this.$store.dispatch('FETCH_DOMESTIC_DEAL');
      }
    },
  },
  data() {
    return {
      slide: 0,
      width: window.innerWidth,
      curDTCode: '',
      tabIndex: 0,
      showMoreState: false,
      showDomesticForMobile: false,
      iActive: 0,
      filterConditions: [],
    };
  },
  created() {
    this.curDTCode = this.dealTypeList?.[0]?.code ?? '';
    if (!this.dealData || this.dealData.findIndex((deal) => deal.dealTypeCode === this.curDTCode) === -1) {
      this.$store.dispatch('FETCH_DEAL_DATA', { dealType: this.curDTCode, moreDeal: false });
    }
  },
  mounted() {
    window.addEventListener('resize', this.resize);
    this.showMoreState = this.isMoreDealData;
  },
  methods: {
    isSalesDeal(item) {
      return this.isBadge(item) || this.isTimer(item);
    },
    isBadge(item) {
      return item.badgeColor && item.badgeText;
    },
    isTimer(item) {
      return item.specialDiscount && item.specialDiscount.endDate && new Date(item.specialDiscount.endDate) > new Date();
    },
    resize() {
      this.width = window.innerWidth;
    },
    showMoreDeal() {
      if (this.curDTCode === 'vacation_pack') {
        this.showMoreState = true;
        this.$store.dispatch('FETCH_DEAL_DATA', { dealType: this.curDTCode, moreDeal: true });
      }
    },
    changeTab(index) {
      this.iActive = index;
    },
  },
};
